#home {
  min-height: 100vh;
  max-height: auto;
  width: 100%;
}

.home-container {
  opacity: 0;
  transform: scale(0.9);
}

.home-container.visible {
  opacity: 1;
  transform: scale(1);
  min-height: 100vh;
  padding: 40px 0px;
  background-image: url(../assets/HomepagePC.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
}

@media (max-width: 500px) {
  .home-container.visible {
    padding: 40px 0px 20px 0px;
    background-image: url(../assets/Homepagemobile.png);
    background-position: center;
  }
}

#home button {
  border: 2px solid black;
  letter-spacing: 3px;
}

#home p {
  letter-spacing: 0.5px;
}
