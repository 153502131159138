#redeem {
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(../assets/gamepagePC.png);
  display: flex;
  justify-content: center;
  align-items: center;
}

.coupon {
  border: 5px dotted rgba(255, 255, 255, 0.603);
}

.submit-btn {
  font-family: GeomanistRegular;
  letter-spacing: 1px;
}

.coupon-container {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}
