@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: KorgeRegular;
  src: url("./assets/KorgeRegular.otf");
}

@font-face {
  font-family: GeomanistRegular;
  src: url("./assets/GeomanistRegular.otf");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

body {
  margin: 0;
  padding: 0;
  height: 100vh;
  font-size: 1.5rem;
  color: white;
}

a {
  text-decoration: none;
  color: white;
}

.title {
  font-family: KorgeRegular;
  word-spacing: 2px;
  letter-spacing: 2px;
  font-size: 50px;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
}

.description {
  font-family: GeomanistRegular;
  letter-spacing: 0.5px;
}

.home-container,
.result-container {
  animation: fadeIn 1s ease-in;
}

.PopShadow {
  box-shadow:
    rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px,
    rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.imgShadow {
  box-shadow:
    rgba(6, 24, 44, 0.4) 0px 0px 0px 2px,
    rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
    rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
